import Login from '../views/authentication/login.jsx';
import Recoverpwd from '../views/authentication/recover-pwd.jsx';
import Getpwd from '../views/authentication/get-pwd.jsx';
import SplashScreen from '../views/authentication/splashscreen.jsx';
//import Login2 from '../views/authentication/login2.jsx';
// import Register from '../views/authentication/register.jsx';
// import Register2 from '../views/authentication/register2.jsx';
// import Lockscreen from '../views/authentication/lockscreen.jsx';
// import Maintanance from '../views/authentication/maintanance.jsx';

// import agencySubmission from '../views/agencysubmission/agencySubmission.jsx';

var authRoutes = [
    { path: '/authentication/login', name: 'Login', icon: 'mdi mdi-account-key', component: Login },
    { path: '/authentication/recover-pwd', name: 'Recover Password', icon: 'mdi mdi-account-convert', component: Recoverpwd },
    { path: '/authentication/get-pwd', name: 'Get Password', icon: 'mdi mdi-account-convert', component: Getpwd },
    { path: '/authentication/splashscreen', name: 'Splash Screen', icon: 'mdi mdi-account-convert', component: SplashScreen },
    //{ path: '/authentication/login2', name: 'Login with Firebase', icon: 'mdi mdi-account-key', component: Login2 },
    // { path: '/authentication/register', name: 'Register', icon: 'mdi mdi-account-plus', component: Register },
    // { path: '/authentication/register2', name: 'Register with Firebase', icon: 'mdi mdi-account-plus', component: Register2 },
    // { path: '/authentication/lockscreen', name: 'Lockscreen', icon: 'mdi mdi-account-off', component: Lockscreen },
    // { path: '/authentication/maintanance', name: 'Maintanance', icon: 'mdi mdi-pencil-box-outline', component: Maintanance }

    // { path: '/agencysubmission', name: 'Agency Submission', icon: 'mdi mdi-account-convert', component: agencySubmission },
];
export default authRoutes; 
import React from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import indexRoutes from './routes/index.jsx';
import { HashRouter, Router, Route, Switch } from 'react-router-dom';

import './assets/scss/style.css';
import './assets/css/default.css';
import './assets/css/media.css';

import { PARSE_APP_ID, PARSE_JAVASCRIPT_KEY, PARSE_API_URL } from './config/parse';
import { Offline, Online, Detector } from "react-detect-offline";
import { parseInitialize, parseInitializewithMaster, authenticateLoggedIn } from './services/parse';
parseInitialize({ app_id: PARSE_APP_ID, key: PARSE_JAVASCRIPT_KEY, url: PARSE_API_URL });


const hist = createBrowserHistory();



ReactDOM.render(
  <>
    <HashRouter>
      <Switch>
        {indexRoutes.map((prop, key) => {
          return <Route path={prop.path} key={key} component={prop.component} />;
        })}
      </Switch>
    </HashRouter>
    {/* <Offline>You're offline right now. Check your connection.</Offline> */}
    {/* <Detector
  render={({ online }) => (
   !online?
   <div className={online ? "normal" : "warningoffline"}>
      You are currently {online ? "online" : "offline"}
    </div>
    :null
  )}
/> */}
  </>
  , document.getElementById('root'));

// const cors = require('cors');
export const uploadFile = async (folder,file) => {
    console.log('uploadFile');
    console.log(folder);
    try{     
           
        var data = new FormData()
        data.append('file', file)
        data.append('folder',folder)
        // if(folder=="trademarks")
        // {
        //     data.append('access','public');
        // }

        const response = await fetch('https://aljwebip.back4app.io/aws-file-upload', {
        // crossDomain:true,
                method: 'post',
                //headers: {'Content-Type':'multipart/form-data'},
                body: data
             });
             if (!response.ok) {
                throw Error(response.statusText);
              }
             const json = await response.json();
             console.log(json);
             var fileName = await json.key.replace(folder+"/","");
             console.log(json);
             return {success:true , filename:fileName ,url:json.Location};
    }
    catch(e)
    {
        console.log(e);
        return {success:false }
    }
}

export const getFileUrl = async (folder,file) => {
    console.log('uploadFile');
    try{     
           
        var data = new FormData()
        data.append('filename', file)
        data.append('folder',folder)

        var details = {
            'filename': file,
            'folder': folder
        };
        
        var formBody = [];
        for (var property in details) {
          var encodedKey = encodeURIComponent(property);
          var encodedValue = encodeURIComponent(details[property]);
          formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");

        const response = await fetch('https://aljwebip.back4app.io/signed-url', {
        // crossDomain:true,
                method: 'post',
                headers: {'Content-Type':'application/x-www-form-urlencoded'},
                body: formBody
             });
             if (!response.ok) {
                throw Error(response.statusText);
              }
             const json = await response.json();
            //  console.log(json);
             return json;
    }
    catch(e)
    {
        console.log(e);
    }
}

export const uploadFileToAWSCustom = async (fileObj, item, folder) => {
    var uploadStatus = await uploadFile(folder, fileObj);
    console.log('uploadFileToAWS', uploadStatus, item);
    if (uploadStatus.success) {
        return { awsFileName: uploadStatus.filename, type: item.type, name: item.name }
    }
}
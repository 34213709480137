import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import Select, { components } from "react-select";
import Datetime from "react-datetime";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import ReCAPTCHA from "react-google-recaptcha";
import classnames from 'classnames';
import { addBreadcrumb, addHeaderTitle } from '../../services/parse';
import { isAdmin } from '../../services/user';
import imageDummy from '../../assets/images/dummy2.jpg';
import iconLink from '../../assets/images/icons/icon-link.png';
import iconEdit from '../../assets/images/icons/icon-edit.png';
import iconDelete from '../../assets/images/icons/icon-delete.png';
import logoLemon from '../../assets/images/icons/logo-lemon.png';
import capchaImg from '../../assets/images/capcha.jpg';
import noImage from '../../assets/images/no-image.jpg';
import iconCamera from '../../assets/images/icons/icon-camera.png';
import iconPencil from '../../assets/images/icons/icon-pencil.png';
import iconMedia from '../../assets/images/icons/icon-media.png';
import iconAudio from '../../assets/images/icons/icon-audio.png';

import { uploadFile, uploadFileToAWSCustom, getFileUrl } from '../../services/awsFileUpload';
import { getLicenseType, getAssetType, getMediaLicenseLibrary, getGeoRestriction, getAgency, getBrand, addRecord, checkFieldValid } from '../../services/agencysubmission';
import { getAssetTypebyName, getMediaLicenseLibrarybyName } from '../../services/medialicenseassetsimport';
import { LOGO_UPLOAD_SIZE } from '../../config/constant';


class mediaAssets extends React.Component {

	constructor(props) {
		super(props);
		this.state = {
			licenseItems: [],
			medialicenselibrary: [],
			assettypes: [],
			georestrictions: [],
			agencylist: [],
			brandlist: [],


			loader: false,

			tempImageView: null,
			imageFileArray: [],
			mainImagefile: [],

			recordstatus: "active",
			allowedlicense: "",
			assetimage: [],
			libraryid: "",
			libraryidvalue: "",
			asseturl: "",
			assetid: "",
			title: "",
			typeid: "",
			typeval: "",
			prepetuity: false,
			expiry: null,
			knownrestrictions: [],
			aganecyid: "",
			aganecyidvalue: "",
			description: "",
			purchasedon: null,
			contributer: "",
			cost: null,
			accountname: "",
			brandpurchased: [],
		};
	}

	async componentDidMount(evt) {
		jQuery(".opendefault").next('.card-body').show();
		jQuery(".opendefault").addClass('active');

		addBreadcrumb('Home / Media License');
		addHeaderTitle('Agency Submission');


		var agencies = await getAgency();
		var agencyOptions = [];
		if (agencies && agencies.length > 0) {
			for (let i = 0; i < agencies.length; i++) {
				agencyOptions.push({ value: agencies[i].id, label: agencies[i].attributes.name })
			}
		}
		this.setState({ agencylist: agencyOptions });


		var medialiclib = await getMediaLicenseLibrary();
		var libraryOptions = [];
		if (medialiclib && medialiclib.length > 0) {
			for (let i = 0; i < medialiclib.length; i++) {
				libraryOptions.push({ value: medialiclib[i].id, label: medialiclib[i].attributes.name })
			}
			libraryOptions.sort(function (a, b) {
				a = a.label.toLowerCase();
				b = b.label.toLowerCase();
				if (a == b) return 0;
				return a < b ? -1 : 1;
			});
		}
		this.setState({ medialicenselibrary: libraryOptions });


		var getassettype = await getAssetType();
		this.setState({ assettypes: getassettype });

		var georestrict = await getGeoRestriction();
		this.setState({ georestrictions: georestrict });


		var agencies = await getAgency();
		var agencyOptions = [];
		if (agencies && agencies.length > 0) {
			for (let i = 0; i < agencies.length; i++) {
				agencyOptions.push({ value: agencies[i].id, label: agencies[i].attributes.name })
			}
		}
		this.setState({ agencylist: agencyOptions });

		var brands = await getBrand();
		this.setState({ brandlist: brands });

		var licensetypes = await getLicenseType();
		this.setState({ licenseItems: licensetypes, });

	}


	removeHttp = (url) => {
		return url.replace(/^https?:\/\//, '');
	}
	fetchFromURL = async (e) => {
		var url = this.state.asseturl;
		url = this.removeHttp(url);
		var splitUrl = url.toString().split('/');

		var assetidfromurl = "";
		var assettitlefromurl = "";
		var assettypetext = "";
		var libraryname = "";

		if (splitUrl[0].includes("shutterstock.com")) {
			libraryname = await getMediaLicenseLibrarybyName("Shutterstock");

			if (splitUrl[1] === "image-photo") {
				assettypetext = await getAssetTypebyName("Image");
			} else if (splitUrl[1] === "image-vector" || splitUrl[1] === "image-illustration") {
				assettypetext = await getAssetTypebyName("Vector");
			} else if (splitUrl[1] === "video") {
				assettypetext = await getAssetTypebyName("Video");
			} else if (splitUrl[1] === "music") {
				assettypetext = await getAssetTypebyName("Audio");
			}

			if (splitUrl[1] === "image-photo" || splitUrl[1] === "image-vector" || splitUrl[1] === "image-illustration") {
				assetidfromurl = splitUrl[2].split("-").pop();
				assettitlefromurl = splitUrl[2].replace('-' + assetidfromurl, '');
			} else if (splitUrl[1] === "video" || splitUrl[1] === "music") {
				var idsplit = splitUrl[2].split("-");
				assetidfromurl = idsplit[1];
				var titlesplit = splitUrl[2].split(idsplit[1] + "-");
				assettitlefromurl = titlesplit[1];
			}

		}

		if (splitUrl[0].includes("stock.adobe.com")) {

			if (splitUrl.length === 4) {
				libraryname = await getMediaLicenseLibrarybyName("Adobestock");
				assetidfromurl = splitUrl[3];
				assettitlefromurl = splitUrl[2];

				if (splitUrl[1] === "images") {
					assettypetext = await getAssetTypebyName("Image");
				} else if (splitUrl[1] === "video") {
					assettypetext = await getAssetTypebyName("Video");
				}
			} else if (splitUrl.length === 5) {
				libraryname = await getMediaLicenseLibrarybyName("Adobestock");
				assetidfromurl = splitUrl[4];
				assettitlefromurl = splitUrl[3];

				if (splitUrl[2] === "images") {
					assettypetext = await getAssetTypebyName("Image");
				} else if (splitUrl[2] === "video") {
					assettypetext = await getAssetTypebyName("Video");
				}
			}



		}

		if (splitUrl[0].includes("gettyimages")) {
			libraryname = await getMediaLicenseLibrarybyName("GettyImages");
			assetidfromurl = splitUrl[4];
			assettitlefromurl = splitUrl[3];

			if (splitUrl[2] === "photo") {
				assettypetext = await getAssetTypebyName("Image");
			} else if (splitUrl[2] === "video") {
				assettypetext = await getAssetTypebyName("Video");
			} else if (splitUrl[2] === "music") {
				assettypetext = await getAssetTypebyName("Video");
			}
		}

		if (splitUrl[0].includes("istockphoto.com")) {
			libraryname = await getMediaLicenseLibrarybyName("iStock");

			if (splitUrl[1] === "photo") {
				assettypetext = await getAssetTypebyName("Image");
			} else if (splitUrl[1] === "vector") {
				assettypetext = await getAssetTypebyName("Vector");
			} else if (splitUrl[1] === "video") {
				assettypetext = await getAssetTypebyName("Video");
			}

			assetidfromurl = splitUrl[2].split("-").pop();
			assettitlefromurl = splitUrl[2].replace('-' + assetidfromurl, '');

		}

		if (assettitlefromurl) {
			assettitlefromurl = assettitlefromurl.replace(/\-/g, ' ');
			assettitlefromurl = assettitlefromurl.charAt(0).toUpperCase() + assettitlefromurl.slice(1);
		}


		this.setState({
			libraryidvalue: libraryname && libraryname[0] ? libraryname[0].get('name') : "",
			libraryid: libraryname && libraryname[0] ? libraryname[0].id : "",
			assetid: assetidfromurl,
			title: assettitlefromurl,
			description: assettitlefromurl,
			typeid: assettypetext ? assettypetext[0].id : "",
		});


	}



	h5Click = (event) => {
		jQuery(event.target).parent().find('.card-body').slideToggle('slow');
		jQuery(event.target).toggleClass('active');
	}



	submitAnother = async () => {
		this.setState({
			tempImageView: null,
			imageFileArray: [],
			mainImagefile: [],

			recordstatus: "active",
			allowedlicense: "",
			assetimage: [],
			libraryid: "",
			libraryidvalue: "",
			asseturl: "",
			assetid: "",
			title: "",
			typeid: "",
			typeval: "",
			prepetuity: false,
			expiry: null,
			knownrestrictions: [],
			aganecyid: "",
			aganecyidvalue: "",
			description: "",
			purchasedon: null,
			contributer: "",
			cost: "",
			accountname: "",
			brandpurchased: [],
			captcha: undefined,
		});

		jQuery('input:checkbox').prop('checked', false);
		jQuery('input:radio[name="licensetype"]').prop('checked', false);
		jQuery('.expirydatewrap').removeClass('d-none');

		jQuery('.step').hide();
		jQuery('.step1').show();
		jQuery('.progtrckr-todo').removeClass('progtrckr-doing').removeClass('progtrckr-done');
		jQuery('.p1').addClass('progtrckr-doing');
	}



	onCaptchaChange = async (value) => {
		console.log(value)
		this.setState({ captcha: value });
	}



	restrictioncheck = async () => {
		var resdata = [];
		jQuery.each(jQuery("input[name='restrictioncheck']:checked"), function () {
			resdata.push(jQuery(this).val());
		});
		this.setState({ knownrestrictions: resdata });
	}
	brandlistcheck = async () => {
		var bdata = [];
		jQuery.each(jQuery("input[name='brandlistcheck']:checked"), function () {
			bdata.push(jQuery(this).val());
		});
		this.setState({ brandpurchased: bdata });
	}




	uploadFileSelected = async (event) => {
		var files = event
		var itemCounts = files.length;
		console.log('itemCounts', itemCounts);
		var itemsToAdd = [];
		var itemsAdded = [];
		if (files.length > 0) {
			for (let i = 0; i < files.length; i++) {
				if (files[i].size < LOGO_UPLOAD_SIZE) {
					var fileType = files[i].type.split("/")[1];
					var fileName = files[i].name;
					var fileSize = (files[i].size / 1000000).toFixed(2);

					var obj = { awsFileName: null, type: fileType, name: fileName, id: itemCounts, size: fileSize }
					itemsToAdd.push(obj);
					var uploadeditem = await uploadFileToAWSCustom(files[i], obj, "contentmaster");
					itemsAdded.push(uploadeditem);
					itemCounts++;
				}
				else {
					console.log('File ' + files[i].name + ' exceeds maximum file size of 30MB')
				}
			}
			if (itemsAdded.length > 0) {
				this.setState({
					imageFileArray: itemsAdded
				});
			}
		}
	}

	// fileChangedHandler = (event) => {
	// 	const file = event.target.files[0];
	// 	if (file) {
	// 		this.setState({ mainImagefile: event.target.files });

	// 		this.readFileToImage(event.target)
	// 	}
	// }

	// readFileToImage = (input) => {
	// 	if (input.files && input.files[0]) {
	// 		var reader = new FileReader();

	// 		reader.onload = function (e) {
	// 			this.setState({ tempImageView: e.target.result });
	// 		}.bind(this);

	// 		reader.readAsDataURL(input.files[0]);
	// 	}
	// }


	fileChangedHandler = (event) => {
		this.setUpImage(event.target)
	}

	handlePaste = (e) => {
		if (e.clipboardData.files.length) {
			this.setUpImage(e.clipboardData)
		}
	};


	setUpImage = (e) => {
		if (e.files.length) {
			const file = e.files[0];
			if (file) {
				const fileSize = file.size;
				if ((file.type === "image/png" || file.type === "image/gif" || file.type === "image/jpeg" || file.type === "image/webp") && fileSize <= 1000000) {
					this.setState({ mainImagefile: e.files, imageError: "" });
					this.readFileToImage(e)
				} else {
					this.setState({ imageError: "Please upload image less than 1 mb" });
				}
			}
		}
	};

	readFileToImage = (input) => {
		if (input.files && input.files[0]) {
			var reader = new FileReader();
			reader.onload = function (e) {
				this.setState({ tempImageView: e.target.result });
			}.bind(this);
			reader.readAsDataURL(input.files[0]);
		}
	}







	stepClick = async (event) => {
		var tabid = jQuery(event.target).data('id');
		var action = jQuery(event.target).data('action');


		var valid = true;
		if (action === "next") {
			valid = await checkFieldValid(tabid, this.state);
		}



		if (valid === true) {
			jQuery('.step').hide();
			jQuery('.step' + tabid).show();

			jQuery('.progtrckr-todo').removeClass('progtrckr-doing').removeClass('progtrckr-done');
			jQuery('.p' + tabid).addClass('progtrckr-doing');

			var prev = tabid - 1;
			for (let i = 1; i <= prev; i++) {
				jQuery('.p' + i).addClass('progtrckr-done');
			}
		}




	}


	submitData = async (event) => {

		this.setState({ loader: true });

		jQuery('#savebutton').prop('disabled', true);

		if (this.state.mainImagefile.length > 0) {
			await this.uploadFileSelected(this.state.mainImagefile)
		}

		var fielddatas = {
			recordstatus: "active",
			assetimage: this.state.imageFileArray,
			allowedlicense: this.state.allowedlicense,

			libraryid: this.state.libraryid,
			asseturl: this.state.asseturl,
			assetid: this.state.assetid,
			title: this.state.title,
			typeid: this.state.typeid,
			prepetuity: this.state.prepetuity,
			expiry: this.state.expiry,
			knownrestrictions: this.state.knownrestrictions,
			aganecyid: this.state.aganecyid,
			description: this.state.description,
			purchasedon: this.state.purchasedon,
			contributer: this.state.contributer,
			cost: this.state.cost,
			accountname: this.state.accountname,
			brandpurchased: this.state.brandpurchased,
		};


		// console.log(fielddatas)


		addRecord(fielddatas).then((value) => {
			console.log(value);

			if (value !== false) {
				jQuery('.step').hide();
				jQuery('.step6').show();

				jQuery('.progtrckr-todo').addClass('progtrckr-done');
			} else {
				// this.setState({ addedclass: 'd-none' });
			}

			this.setState({ loader: false });
			jQuery('#savebutton').prop('disabled', false);
		});






	}


	render() {

		var expiring = "";
		if (this.state.expiry) {
			var date1 = new Date();
			var date2 = new Date(this.state.expiry);
			var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);

			expiring = diffDays + 1;
		}



		return (
			<div className="animated fadeIn">


				{
					this.state.loader === true ?
						<div className="loaderwrap"><div className="loader"></div></div>
						:
						null
				}

				<Card>
					<CardBody>
						<div className="formsteps">

							<ol className="progtrckr">
								<li className="p1 progtrckr-todo progtrckr-doing" data-id="1"><em>1</em><span>Basic Info</span></li>
								<li className="p2 progtrckr-todo no-hl" data-id="2"><em>2</em><span>Assets Details</span></li>
								<li className="p3 progtrckr-todo no-hl" data-id="3"><em>3</em><span>License Info</span></li>
								<li className="p4 progtrckr-todo no-hl" data-id="4"><em>4</em><span>Additional Info</span></li>
								<li className="p5 progtrckr-todo no-hl" data-id="5"><em>5</em><span>Review</span></li>
								<li className="p6 progtrckr-todo no-hl" data-id="6"><em>6</em><span>Submit</span></li>
							</ol>

							<div className="step step1 mt-5">
								<Row>
									<Col md="6">
										<Card className="customAcc">
											<CardTitle className="opendefault">
												<i className="icon-circle icon-pencil"></i>
												Basic Info
											</CardTitle>
											<CardBody>
												<Form>

													<FormGroup>
														<Label>Asset URL</Label>
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText><i className="fas fa-link"></i></InputGroupText>
															</InputGroupAddon>
															<Input type="text" name="asseturl" placeholder="Paste URL here"
																value={this.state.asseturl}
																onChange={
																	async (event) => {
																		this.setState({ asseturl: event.target.value });
																	}
																}
															/>
															<Button className="btn btn-default fetchbtn" onClick={this.fetchFromURL} >
																Fetch <i className="mdi mdi-download"></i>
															</Button>

														</InputGroup>
													</FormGroup>

													<FormGroup>
														<Label>Title *</Label>
														<Input type="text" name="title" id="title"
															value={this.state.title}
															onChange={
																async (event) => {
																	this.setState({ title: event.target.value });
																}
															}
														/>
													</FormGroup>

													<FormGroup>
														<Label>Agency</Label>
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText><i className="fas fa-book"></i></InputGroupText>
															</InputGroupAddon>
															<Select
																value={
																	this.state.aganecyid ?
																		[{ value: this.state.aganecyid, label: this.state.aganecyidvalue }]
																		:
																		""
																}
																isClearable
																isSearchable
																name="color"
																options={this.state.agencylist ? this.state.agencylist : null}
																onChange={
																	async (selectedOption) => {
																		this.setState({
																			aganecyid: selectedOption ? selectedOption.value : "",
																			aganecyidvalue: selectedOption ? selectedOption.label : ""
																		});
																	}
																}
															/>

														</InputGroup>
													</FormGroup>

												</Form>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="footer-buttons">
									<button data-id="2" data-action="next" className="btn btn-next" onClick={this.stepClick}>Next</button>
								</div>
							</div>
							{/* Step1 end */}



							<div className="step step2 mt-5">
								<Row>
									<Col md="6">
										<Card className="customAcc">
											<CardTitle className="opendefault">
												<i className="icon-circle icon-pencil"></i>
												Assets Details
											</CardTitle>
											<CardBody>
												<Form>
													<FormGroup>
														<Label>Library *</Label>
														<Select
															value={
																this.state.libraryid ?
																	[{ value: this.state.libraryid, label: this.state.libraryidvalue }]
																	:
																	""
															}
															id="libraryid"
															className="libraryidselect"
															isClearable
															isSearchable
															name="color"
															options={this.state.medialicenselibrary ? this.state.medialicenselibrary : null}
															onChange={
																async (selectedOption) => {
																	this.setState({
																		libraryid: selectedOption ? selectedOption.value : "",
																		libraryidvalue: selectedOption ? selectedOption.label : ""
																	});
																}
															}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Asset ID</Label>
														<Input type="text" name="assetid" placeholder="Asset ID"
															value={this.state.assetid}
															onChange={
																async (event) => {
																	this.setState({ assetid: event.target.value });
																}
															}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Asset Type *</Label>
														<Input type="select" className="custom-select" id="assettype"
															onChange={(event) => {
																var text = jQuery("#assettype option:selected").text()
																this.setState({
																	typeid: event.target.value,
																	typeval: text,
																});
															}}
															value={this.state.typeid}
														>
															<option value="">Select</option>
															{
																this.state.assettypes && this.state.assettypes.map((dynamicData, key) =>
																	<option value={dynamicData.id} key={key}>{dynamicData.get('name')}</option>
																)
															}
														</Input>
													</FormGroup>
												</Form>
											</CardBody>
										</Card>
									</Col>
									<Col md="6" onPaste={this.handlePaste}>
										<Card className="customAcc">
											<CardTitle className="opendefault">
												<i className="icon-circle icon-pencil"></i>
												Cover Image *
											</CardTitle>
											<CardBody>
												<Form>

													<div className="bulkfield p-0 libraryimgwrap">
														{
															this.state.imageError !== "" ?
																<span className="error fixedtop">{this.state.imageError}</span>
																:
																null
														}

														<Form>
															<InputGroup>
																{
																	this.state.tempImageView ?
																		<img src={this.state.tempImageView} alt='img' />
																		:
																		<div className="customupload">
																			<div className="dragndrop">
																				Choose or Drag and Drop image
																				<Input type="file" name="libraryimage" id='libraryimage' accept="image/png, image/gif, image/jpeg, image/webp" placeholder='upload your file' onChange={this.fileChangedHandler} />
																			</div>
																			Paste image ctrl + V
																		</div>
																}

															</InputGroup>
														</Form>

														<div className='optionbx'>
															<a href='javascript:' className='delete'
																onClick={
																	async (event) => {
																		this.setState({ tempImageView: null, mainImagefile: [], imageFileArray: [] });
																		jQuery("#libraryimage").val(null);
																	}
																}>
																<img src={iconDelete} alt='img' />
															</a>
															<div id='linkinput' className='linkinput'>
																<Input type="text" name="imageid" placeholder='Enter image Id' />
																<button className="submitbx"><img src={iconLink} alt='img' /></button>
															</div>
														</div>
													</div>
												</Form>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="footer-buttons">
									<button data-id="1" data-action="prev" className="btn btn-prev" onClick={this.stepClick}>Previous</button>
									<button data-id="3" data-action="next" className="btn btn-next" onClick={this.stepClick}>Next</button>
								</div>
							</div>
							{/* Step2 end */}



							<div className="step step3 mt-5">
								<Row>
									<Col md="6">
										<Card className="customAcc">
											<CardTitle className="opendefault">
												<i className="icon-circle icon-pencil"></i>
												Assets Details
											</CardTitle>
											<CardBody>
												<Form>
													<FormGroup>
														<Label>In prepetuity</Label>
														<div className="checkslide">
															<Input name="prepetuity" id="prepetuity" type="checkbox" className="form-check-input" value="prepetuity"
																onClick={
																	async (event) => {
																		this.setState({ prepetuity: event.target.checked });
																		if (event.target.checked === true) {
																			jQuery('.expirydatewrap').addClass('d-none');
																			this.setState({ expiry: null });
																		} else {
																			jQuery('.expirydatewrap').removeClass('d-none');
																		}
																	}
																}
															/>
															<label for="prepetuity" className="">prepetuity</label>
														</div>
													</FormGroup>
													<FormGroup className="expirydatewrap">
														<Label>Expiry *</Label>
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText><i className="fas fa-calendar-alt"></i></InputGroupText>
															</InputGroupAddon>

															<DatePicker
																onClickOutside={this.datePickerValue}
																dateFormat="dd-MM-yyyy"
																id="expiry_date"
																yearDropdownItemNumber={100}
																scrollableYearDropdown={true}
																showYearDropdown
																showMonthDropdown
																placeholderText="dd-MM-yyyy"
																onChange={(selectedOption) => {
																	this.setState({ expiry: selectedOption });
																}}
																selected={this.state.expiry}
															/>

														</InputGroup>
													</FormGroup>
													<FormGroup>
														<Label>Known Restrictions</Label>
														<div className='allowassets d-flex flex-wrap'>
															{
																this.state.georestrictions && this.state.georestrictions.map((dynamicData, key) =>
																	<div className='checkbox' key={key}>
																		<Input type="checkbox" name="restrictioncheck" id={`restrict${key}`} value={dynamicData.id}
																			onChange={this.restrictioncheck}
																		></Input>
																		<label htmlFor={`restrict${key}`}>{dynamicData.get('name')}</label>
																	</div>
																)
															}

														</div>
													</FormGroup>
													<FormGroup>
														<Label>License Type *</Label>
														<div className='allowassets licenseitems d-flex flex-wrap'>
															{
																this.state.licenseItems && this.state.licenseItems.map((dynamicData, key) =>
																	<div className='radiobx' key={key}>
																		<Input type="radio" name="licensetype" value={dynamicData.id} id={`lice${key}`}
																			onChange={
																				async (event) => {
																					this.setState({ allowedlicense: event.target.value });
																				}
																			}
																		></Input>
																		<label htmlFor={`lice${key}`}>{dynamicData.get('name')}</label>
																	</div>
																)
															}

														</div>
													</FormGroup>
												</Form>
											</CardBody>
										</Card>
									</Col>

								</Row>
								<div className="footer-buttons">
									<button data-id="2" data-action="prev" className="btn btn-prev" onClick={this.stepClick}>Previous</button>
									<button data-id="4" data-action="next" className="btn btn-next" onClick={this.stepClick}>Next</button>
								</div>
							</div>
							{/* Step3 end */}



							<div className="step step4 mt-5">
								<Row>
									<Col md="6">
										<Card className="customAcc">
											<CardTitle className="opendefault">
												<i className="icon-circle icon-pencil"></i>
												Assets Details
											</CardTitle>
											<CardBody>
												<Form>
													<FormGroup>
														<Label>Description</Label>
														<Input type="textarea" placeholder="Add your notes here..." rows="5"
															value={this.state.description}
															onChange={
																async (event) => {
																	this.setState({ description: event.target.value });
																}
															}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Purchased on</Label>
														<InputGroup>
															<InputGroupAddon addonType="prepend">
																<InputGroupText><i className="fas fa-calendar-alt"></i></InputGroupText>
															</InputGroupAddon>

															<DatePicker
																onClickOutside={this.datePickerValue}
																maxDate={new Date()}
																dateFormat="dd-MM-yyyy"
																yearDropdownItemNumber={100}
																scrollableYearDropdown={true}
																showYearDropdown
																showMonthDropdown
																placeholderText="DD-MM-YYYY"
																onChange={(selectedOption) => {
																	this.setState({ purchasedon: selectedOption });
																}}
																selected={this.state.purchasedon}
															/>


															{/* <Datetime
																locale="en-gb"
																timeFormat={false}
																dateFormat="DD/MM/YYYY"
																inputProps={{ placeholder: "Purchased on" }}
																onChange={(selectedOption) => {
																	this.setState({ purchasedon: selectedOption._isValid === true ? selectedOption.toDate() : null });
																}}
																value={this.state.purchasedon}
															/> */}
														</InputGroup>
													</FormGroup>
													<FormGroup>
														<Label>Contributor</Label>
														<Input type="text" name="contributor" placeholder="Contributor"
															value={this.state.contributer}
															onChange={
																async (event) => {
																	this.setState({ contributer: event.target.value });
																}
															}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Cost (USD)</Label>
														<Input type="number" name="cost" placeholder="Cost"
															value={this.state.cost}
															onChange={
																async (event) => {
																	this.setState({ cost: event.target.value });
																}
															}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Account Name</Label>
														<Input type="text" name="accountname" placeholder="Account Name"
															value={this.state.accountname}
															onChange={
																async (event) => {
																	this.setState({ accountname: event.target.value });
																}
															}
														/>
													</FormGroup>
													<FormGroup>
														<Label>Abdul Lateef Jameel brand purchased for</Label>
														<div className='allowassets d-flex flex-wrap'>
															{
																this.state.brandlist && this.state.brandlist.map((dynamicData, key) =>
																	<div className='checkbox' key={key}>
																		<Input type="checkbox" name="brandlistcheck" id={`brandlist${key}`} value={dynamicData.id}
																			onChange={this.brandlistcheck}
																		></Input>
																		<label htmlFor={`brandlist${key}`}>{dynamicData.get('name')}</label>
																	</div>
																)
															}

														</div>
													</FormGroup>
												</Form>
											</CardBody>
										</Card>
									</Col>

								</Row>
								<div className="footer-buttons">
									<button data-id="3" data-action="prev" className="btn btn-prev" onClick={this.stepClick}>Previous</button>
									<button data-id="5" data-action="next" className="btn btn-next" onClick={this.stepClick}>Next</button>
								</div>
							</div>
							{/* Step4 end */}



							<div className="step step5 mt-5">
								<Row className="justify-content-between align-items-center">
									<Col md="6">
										<Card>
											<CardTitle className="opendefault">
												<i className="icon-circle icon-pencil"></i>
												Review and Submit
											</CardTitle>
											<CardBody>

												<div className="assetscard">
													<Row className="justify-content-between">
														<Col>
															<h2>ID# {this.state.assetid}</h2>
														</Col>
														<Col className="col-auto">
															<div className="editassets">
																<a href="javascript:" onClick={this.stepClick}>
																	<img src={iconEdit} alt="img" data-id="1" data-action="next" />
																</a>
															</div>
														</Col>
													</Row>

													<Row>
														<Col className="col-12 mt-2">
															<p className="fs20">{this.state.description}</p>
														</Col>
													</Row>

													<div className="imgbx">
														<a href="javascript:" onClick={this.toggle2}>
															{
																this.state.tempImageView ?
																	<img src={this.state.tempImageView} alt='img' />
																	:
																	<img src={noImage} alt="img" />
															}
														</a>
													</div>

													<div className="row mt-2 mb-3">
														<div className="col">
															<div className="librarydetail">{this.state.libraryidvalue}</div>
														</div>
														<div className="col-auto col">
															{/* <div className="timeago">Added 6 days ago</div> */}
														</div>
													</div>

													<div className="row">
														<div className="col pr-0">
															{/* {
																expiring > 0 ?
																	<span className="expiringsoon">Expiring in {expiring} days</span>
																	:
																	<span className="expiringsoon">Expired</span>
															} */}

														</div>
														<div className="col-auto col">
															<div className="agencydetail">
																{/* <img src={logoLemon} alt="img" /> */}
																{
																	this.state.typeval === "Image" ?
																		<img src={iconCamera} alt="img" />
																		:
																		this.state.typeval === "Video" ?
																			<img src={iconMedia} alt="img" />
																			:
																			this.state.typeval === "Audio" ?
																				<img src={iconAudio} alt="img" />
																				:
																				this.state.typeval === "Vector" ?
																					<img src={iconPencil} alt="img" />
																					:
																					null
																}
															</div>
														</div>
													</div>
												</div>

											</CardBody>
										</Card>
									</Col>

									<Col md="6">
										<Card>
											<CardBody>

												<div className="agencyformsubmit">
													<div className="capchabx mb-4">
														{/* <img src={capchaImg} alt="img" /> */}
														<ReCAPTCHA
															sitekey="6LdXDFkUAAAAADdwC2RGfnsqXN5n9yiLJYJktrir"
															onChange={this.onCaptchaChange}
															onExpired={this.onCaptchaChange}
															onErrored={this.onCaptchaChange}
														/>
														{/* {formerrors.captchaerror ? <span className="error">{formerrors.captchaerror}</span> : null} */}
													</div>
													{
														this.state.captcha === undefined ?
															<Button className="btn btn-default" disabled>Submit</Button>
															:
															<Button className="btn btn-default" id="savebutton" onClick={this.submitData}>Submit</Button>
													}

												</div>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="footer-buttons">
									<button data-id="4" data-action="prev" className="btn btn-prev" onClick={this.stepClick}>Previous</button>
									{/* <button data-id="6" data-action="next" className="btn btn-next" onClick={this.stepClick}>Next</button> */}
								</div>
							</div>
							{/* Step5 end */}



							<div className="step step6 mt-5">
								<Row>

									<Col md="6">
										<Card>
											<CardBody>
												<h3 className="mb-4">Submitted</h3>
												<p className="fs18">ID <strong className="bluetext"> #{this.state.assetid}</strong></p>
												<p className="fs18">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum.</p>
												<Button className="btn btn-default mt-3" onClick={() => { this.submitAnother() }}>Submit another</Button>



												<a href="javascript:" className="btn btn-primary mt-3 ml-3" onClick={() => { this.submitAnother() }}>Close</a>
											</CardBody>
										</Card>
									</Col>

									<Col md="6">
										<Card>
											<CardBody>

												<div className="assetscard">
													<Row className="justify-content-between">
														<Col>
															<h2>ID# {this.state.assetid}</h2>
														</Col>
													</Row>

													<Row>
														<Col className="col-12 mt-2">
															<p className="fs20">{this.state.description}</p>
														</Col>
													</Row>

													<div className="imgbx">
														<a href="javascript:" onClick={this.toggle2}>
															{
																this.state.tempImageView ?
																	<img src={this.state.tempImageView} alt='img' />
																	:
																	<img src={noImage} alt="img" />
															}
														</a>
													</div>

													<div className="row mt-2 mb-3">
														<div className="col">
															<div className="librarydetail">{this.state.libraryidvalue}</div>
														</div>
														<div className="col-auto col">
															{/* <div className="timeago">Added 6 days ago</div> */}
														</div>
													</div>

													<div className="row">
														<div className="col pr-0">
															{/* {
																expiring > 0 ?
																	<span className="expiringsoon">Expiring in {expiring} days</span>
																	:
																	<span className="expiringsoon">Expired</span>
															} */}
														</div>
														<div className="col-auto col">
															<div className="agencydetail">
																{
																	this.state.typeval === "Image" ?
																		<img src={iconCamera} alt="img" />
																		:
																		this.state.typeval === "Video" ?
																			<img src={iconMedia} alt="img" />
																			:
																			this.state.typeval === "Audio" ?
																				<img src={iconAudio} alt="img" />
																				:
																				this.state.typeval === "Vector" ?
																					<img src={iconPencil} alt="img" />
																					:
																					null
																}
															</div>
														</div>
													</div>
												</div>
											</CardBody>
										</Card>
									</Col>
								</Row>
								<div className="footer-buttons">
									<button data-id="5" data-action="prev" className="btn btn-prev" onClick={this.stepClick}>Previous</button>
								</div>
							</div>
							{/* Step6 end */}

						</div>
					</CardBody>
				</Card>
			</div>
		)
	}
}

export default mediaAssets;

import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'react-scrollbar';
import { Row, Col, Carousel, CarouselItem, CarouselControl, Button, Table, Input, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Pagination, PaginationItem, PaginationLink, Modal, ModalHeader, ModalBody, ModalFooter, Label, Alert, UncontrolledDropdown, DropdownMenu, DropdownToggle, DropdownItem, TabContent, TabPane, CardBody, Nav, NavItem, NavLink, Card, CardTitle, CardText } from 'reactstrap';
import 'react-datetime/css/react-datetime.css';
import jQuery from 'jquery';
import classnames from 'classnames';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import imageDummy from '../../../assets/images/dummy.jpg';
import iconLink from '../../../assets/images/icons/icon-link.png';
import iconCamera from '../../../assets/images/icons/icon-camera.png';
import iconPencil from '../../../assets/images/icons/icon-pencil.png';
import iconMedia from '../../../assets/images/icons/icon-media.png';
import iconAudio from '../../../assets/images/icons/icon-audio.png';
import iconShare from '../../../assets/images/icons/icon-share.png';
import Moment from 'moment';

import Assetimagebulk from "./Assetimagebulk";

function deleteRecord() {
  document.getElementById("detelepopup").classList.add("active");
}
function closeDeleteRecord() {
  document.getElementById("detelepopup").classList.remove("active");
}

function setobjectid(num) {
  var elSlide = jQuery('.mediaslideitem[data-id=' + num + ']').data('obj');
  jQuery('#hiddenobjectID').val(elSlide);
}

var popSlide = {
  autoplay: false,
  autoplaySpeed: 5000,
  dots: false,
  arrows: true,
  infinite: true,
  speed: 800,
  slidesToShow: 1,
  slidesToScroll: 1,
  afterChange: (current) => setobjectid(current),
};


class Viewassets extends React.Component {
  constructor(props) {
    super(props);

    this.slideRef = React.createRef();

    this.state = {
      activeTab: '1',
    };

  }


  async componentDidMount(evt) {

  }


  async componentDidUpdate(prevProps, prevState) {
    if (this.props.isOpen && this.props.viewObjectID && prevProps.viewObjectID !== this.props.viewObjectID) {
      jQuery('#hiddenobjectID').val(this.props.viewObjectID);
      this.movetoSlide(this.props.viewObjectID);
    }

  }


  toggleTabs(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  }

  movetoSlide = async (objid) => {
    var moveobjnumber = jQuery('.mediaslideitem[data-obj=' + objid + ']').data('id');
    this.slideRef.current.slickGoTo(moveobjnumber)
  }




  render() {
    return (
      <Modal isOpen={this.props.isOpen} toggle={this.props.toggle} className='mediamodel viewpopup'>
        <input type="hidden" id="hiddenobjectID" />


        <div className="modal-header">
          <h5 className="modal-title">View added records</h5>
          <a className="closebtn" onClick={this.props.toggle}>x</a>
        </div>

        <Slider ref={this.slideRef} className='ddd' {...popSlide}>




          {
            this.props.viewListFull ?

              this.props.viewListFull.map((dynamicData, key) => {

                var expiring = "";
                if (dynamicData.get('expiry')) {
                  var date1 = new Date();
                  var date2 = new Date(dynamicData.get('expiry'));
                  var diffDays = parseInt((date2 - date1) / (1000 * 60 * 60 * 24), 10);

                  expiring = diffDays + 1;
                }

                return (
                  <div className="item mediaslideitem" data-id={key} data-obj={dynamicData.id}>
                    <ModalBody>
                      <div className="addlibrary addassets">

                        <Row className="mb-4 justify-content-between">
                          <Col className="col">

                            <div className="activecheck">
                              <Input id="viewstatus" type="checkbox" className="form-check-input form-check-input"
                                checked={dynamicData.get('recordstatus') === "active" ? true : false}
                              />
                              <label for="viewstatus" className="btn">
                                <i className="mr-1 fas fa-recycle"></i>
                                Active
                              </label>
                            </div>
                          </Col>
                          <Col className="col-md-auto">
                            <div className='allowassets noclick d-flex flex-wrap'>

                              <div className='radiobx'>
                                <Input type="radio" name="radiohi" id="radioh11"></Input>
                                <label htmlFor="radioh11">
                                  {
                                    dynamicData.get("licensetype") ? dynamicData.get("licensetype").get('name') : null
                                  }
                                </label>
                              </div>

                            </div>
                          </Col>
                        </Row>

                        <Row>
                          <Col className="col-md-5">
                            <div className="imgbx">

                              <Assetimagebulk
                                objid={dynamicData.id}
                                imageurl={dynamicData.get('assetimage') ? dynamicData.get('assetimage')[0] : ""}
                                // updateIDforImage={this.state.updateIDforImage}
                                popupstatus="view"
                              />

                              <div className='optionbx'>
                                <a href={dynamicData.get('asseturl') ? dynamicData.get('asseturl') : ""} target="_blank">
                                  {
                                    dynamicData.get('assettypetext') === "Image" ?
                                      <img src={iconCamera} alt="img" />
                                      :
                                      dynamicData.get('assettypetext') === "Video" ?
                                        <img src={iconMedia} alt="img" />
                                        :
                                        dynamicData.get('assettypetext') === "Audio" ?
                                          <img src={iconAudio} alt="img" />
                                          :
                                          dynamicData.get('assettypetext') === "Vector" ?
                                            <img src={iconPencil} alt="img" />
                                            :
                                            null
                                  }
                                </a>
                                {/* <a href='javascript:' className='sharebtn'><img src={iconShare} alt='img' /></a> */}

                              </div>
                            </div>
                          </Col>
                          <Col className="col-md-7">
                            <div className='textbx'>
                              <h3 className="mb-4">{dynamicData.get('title')}</h3>

                              <FormGroup className="d-flex align-items-center">
                                <Label>Library</Label>
                                <p>
                                  {
                                    dynamicData.get('medialicenselibraryid') ?
                                      dynamicData.get('medialicenselibraryid').get('name')
                                      :
                                      ""
                                  }
                                </p>
                              </FormGroup>
                              <FormGroup className="d-flex align-items-center">
                                <Label>Assets ID</Label>
                                <p><a href={dynamicData.get('asseturl')} target="_blank">{dynamicData.get('assetid')}</a></p>
                              </FormGroup>
                              <FormGroup className="d-flex align-items-center">
                                <Label>Title</Label>
                                <p>{dynamicData.get('title')}</p>
                              </FormGroup>
                              <FormGroup className="d-flex align-items-center">
                                <Label>Type</Label>
                                <p>
                                  {
                                    dynamicData.get('assettypeid') ?
                                      dynamicData.get('assettypetext')
                                      :
                                      ""
                                  }
                                </p>
                              </FormGroup>

                              {
                                expiring !== "" ?
                                  <FormGroup className="d-flex align-items-center">
                                    <Label>Expiry</Label>
                                    {
                                      expiring > 0 ?
                                        <p>{expiring} days</p>
                                        :
                                        <p>Expired</p>
                                    }
                                  </FormGroup>
                                  :
                                  null
                              }


                              <FormGroup className="d-flex align-items-center">
                                <Label>Known Restrictions</Label>
                                <div className='allowassets noclick d-flex flex-wrap'>
                                  {
                                    dynamicData.get('georestriction') ?
                                      dynamicData.get('georestriction').map((geoData, key) => {
                                        return (
                                          <div className='checkbox' key={key}>
                                            <Input type="checkbox" id={`viewgeo${key}`} disabled></Input>
                                            <label htmlFor={`viewgeo${key}`}>{geoData.get('name')}</label>
                                          </div>
                                        )
                                      }
                                      )
                                      :
                                      null
                                  }
                                </div>
                              </FormGroup>

                              <FormGroup className="d-flex align-items-center">
                                <Label>Agency</Label>
                                <p>
                                  {
                                    dynamicData.get('agencyid') ?
                                      dynamicData.get('agencyid').get('name')
                                      :
                                      ""
                                  }
                                </p>
                              </FormGroup>
                            </div>
                          </Col>
                        </Row>


                        <div className="extrainfosec mt-2">
                          <Nav tabs>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '1' })}
                                onClick={() => { this.toggleTabs('1'); }}
                              >
                                Description
                              </NavLink>
                            </NavItem>
                            <NavItem>
                              <NavLink
                                className={classnames({ active: this.state.activeTab === '2' })}
                                onClick={() => { this.toggleTabs('2'); }}
                              >
                                Extra Info
                              </NavLink>
                            </NavItem>
                          </Nav>
                          <TabContent activeTab={this.state.activeTab}>
                            <TabPane tabId="1">
                              <div className="tabcontent p-3">
                                <Row>
                                  <Col sm="12">
                                    <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '195px' }}>
                                      <p>{dynamicData.get('description')}</p>
                                    </ScrollArea>
                                  </Col>
                                </Row>
                              </div>
                            </TabPane>
                            <TabPane tabId="2">
                              <div className="tabcontent p-3">
                                <Row className="justify-content-between">
                                  <Col className="col-md-6">
                                    <FormGroup className="d-flex align-items-center">
                                      <Label>Purchased on</Label>
                                      <p>
                                        {
                                          dynamicData.get('purchasedon') ?
                                            Moment(dynamicData.get('purchasedon')).format('YYYY-MM-DD')
                                            :
                                            ""
                                        }
                                      </p>
                                    </FormGroup>
                                    <FormGroup className="d-flex align-items-center">
                                      <Label>Contributer</Label>
                                      <p>{dynamicData.get('contributer')}</p>
                                    </FormGroup>
                                  </Col>
                                  <Col className="col-md-6">
                                    <FormGroup className="d-flex align-items-center">
                                      <Label>Cost (USD)</Label>
                                      <p>{dynamicData.get('cost')} USD</p>
                                    </FormGroup>
                                    <FormGroup className="d-flex align-items-center">
                                      <Label>Account Name</Label>
                                      <p>{dynamicData.get('accountname')}</p>
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <div className="extrainfobx">
                                  <FormGroup className="d-flex align-items-center mb-0">
                                    <Label>ALJ brand purchased for</Label>
                                    <div className='allowassets d-flex flex-wrap'>
                                      {
                                        dynamicData.get('aljbrand') ?
                                          dynamicData.get('aljbrand').map((brandData, key) => {
                                            return (
                                              <div className='checkbox' key={key}>
                                                <Input type="checkbox" id={`viewbrand${key}`} disabled></Input>
                                                <label htmlFor={`viewbrand${key}`}>{brandData.get('name')}</label>
                                              </div>
                                            )
                                          }
                                          )
                                          :
                                          null
                                      }

                                    </div>
                                  </FormGroup>
                                </div>

                              </div>
                            </TabPane>
                          </TabContent>
                        </div>

                      </div>
                    </ModalBody>
                  </div>
                )

              })
              :
              null
          }












          {/* <div className="item mediaslideitem" data-id="0" data-obj="c1">
            <ModalBody>
              <div className="addlibrary addassets">

                <Row className="mb-4 justify-content-between">
                  <Col className="col">
                    <span className="btn btnactive float-left noclick"><i className="mr-1 fas fa-recycle"></i> Active</span>
                  </Col>
                  <Col className="col-md-auto">
                    <div className='allowassets noclick d-flex flex-wrap'>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh11"></Input><label htmlFor="radioh11">Editorial</label></div>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh12"></Input><label htmlFor="radioh12">Non Editorial</label></div>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh13"></Input><label htmlFor="radioh13">Royalty Free</label></div>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh14"></Input><label htmlFor="radioh14">Standard</label></div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-md-5">
                    <div className="imgbx">
                      <img src={imageDummy} alt='img' />
                      <div className='optionbx'>
                        <a href="https://www.shutterstock.com/image-vector/futuristic-covers-design-cool-isometric-patterns-590641016" target="_blank"><img src={iconCamera} alt='img' /></a>
                        <a href='javascript:' className='sharebtn'><img src={iconShare} alt='img' /></a>
                        <div id='linkinput' className='linkinput'>
                          <Input type="text" name="imageid" placeholder='Enter image Id' />
                          <button className="submitbx"><img src={iconLink} alt='img' /></button>
                        </div>
                        <div id='linkupload' className='linkupload d-none'>
                          <Input type="file" name="imageid" id='openimagedit' placeholder='upload your file' />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-7">
                    <div className='textbx'>
                      <h3 className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum.</h3>

                      <FormGroup className="d-flex align-items-center">
                        <Label>Library</Label>
                        <p>Shutterstock</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Assets ID</Label>
                        <p><a href="https://www.shutterstock.com/" target="_blank">1156565656</a></p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Title</Label>
                        <p>Lemon</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Type</Label>
                        <p>Photo</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Expiry</Label>
                        <p>50 days</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Known Restrictions</Label>
                        <div className='allowassets noclick d-flex flex-wrap'>
                          <div className='checkbox'><Input type="checkbox" id="checksm1" disabled></Input><label htmlFor="checksm1">Web</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm2" disabled></Input><label htmlFor="checksm2">Print</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm3" disabled></Input><label htmlFor="checksm3">Outdoor</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm4" disabled></Input><label htmlFor="checksm4">Others</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm5" disabled></Input><label htmlFor="checksm5">Print</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm6" disabled></Input><label htmlFor="checksm6">Outdoor</label></div>
                        </div>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Agency</Label>
                        <p>Lemon LLC</p>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>


                <div className="extrainfosec mt-2">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggleTabs('1'); }}
                      >
                        Description
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggleTabs('2'); }}
                      >
                        Extra Info
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="tabcontent p-3">
                        <Row>
                          <Col sm="12">
                            <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '195px' }}>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam imperdiet est magna, hendrerit aliquam nibh placerat cursus. Phasellus sed nisl dolor. In malesuada finibus nulla, sit amet pretium dui porttitor at. Fusce pellentesque velit at lacinia sodales. Fusce fringilla leo a nisl posuere sodales. Sed at tellus in leo hendrerit iaculis. Suspendisse vel fermentum sem. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</p>
                            </ScrollArea>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="tabcontent p-3">
                        <Row className="justify-content-between">
                          <Col className="col-md-6">
                            <FormGroup className="d-flex align-items-center">
                              <Label>Purchased on</Label>
                              <p>2022-08-05</p>
                            </FormGroup>
                            <FormGroup className="d-flex align-items-center">
                              <Label>Contributer</Label>
                              <p>Agency XYZ</p>
                            </FormGroup>
                          </Col>
                          <Col className="col-md-6">
                            <FormGroup className="d-flex align-items-center">
                              <Label>Cost (USD)</Label>
                              <p>500 USD</p>
                            </FormGroup>
                            <FormGroup className="d-flex align-items-center">
                              <Label>Account Name</Label>
                              <p>ALJ Corp</p>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="extrainfobx">
                          <FormGroup className="d-flex align-items-center mb-0">
                            <Label>ALJ brand purchased for</Label>
                            <div className='allowassets d-flex flex-wrap'>
                              <div className='checkbox'><Input type="checkbox" id="checkextra1" disabled></Input><label htmlFor="checkextra1">FRV</label></div>
                              <div className='checkbox'><Input type="checkbox" id="checkextra2" disabled></Input><label htmlFor="checkextra2">Jimco</label></div>
                              <div className='checkbox'><Input type="checkbox" id="checkextra3" disabled></Input><label htmlFor="checkextra3">ALJ Health</label></div>
                              <div className='checkbox'><Input type="checkbox" id="checkextra4" disabled></Input><label htmlFor="checkextra4">Jenoa</label></div>
                            </div>
                          </FormGroup>
                        </div>

                      </div>
                    </TabPane>
                  </TabContent>
                </div>

              </div>
            </ModalBody>
          </div>

          <div className="item mediaslideitem" data-id="1" data-obj="c2">
            <ModalBody>
              <div className="addlibrary addassets">

                <Row className="mb-4 justify-content-between">
                  <Col className="col-12 mb-4">
                    <h5 className="modal-title">Add New Asset</h5>
                  </Col>
                  <Col className="col">
                    <span className="btn btnactive float-left noclick"><i className="mr-1 fas fa-recycle"></i> Active</span>
                  </Col>
                  <Col className="col-md-auto">
                    <div className='allowassets noclick d-flex flex-wrap'>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh11"></Input><label htmlFor="radioh11">Editorial</label></div>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh12"></Input><label htmlFor="radioh12">Non Editorial</label></div>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh13"></Input><label htmlFor="radioh13">Royalty Free</label></div>
                      <div className='radiobx'><Input type="radio" name="radiohi" id="radioh14"></Input><label htmlFor="radioh14">Standard</label></div>
                    </div>
                  </Col>
                </Row>

                <Row>
                  <Col className="col-md-5">
                    <div className="imgbx">
                      <img src={imageDummy} alt='img' />
                      <div className='optionbx'>
                        <a href="https://www.shutterstock.com/image-vector/futuristic-covers-design-cool-isometric-patterns-590641016" target="_blank"><img src={iconCamera} alt='img' /></a>
                        <a href='javascript:' className='sharebtn'><img src={iconShare} alt='img' /></a>
                        <div id='linkinput' className='linkinput'>
                          <Input type="text" name="imageid" placeholder='Enter image Id' />
                          <button className="submitbx"><img src={iconLink} alt='img' /></button>
                        </div>
                        <div id='linkupload' className='linkupload d-none'>
                          <Input type="file" name="imageid" id='openimagedit' placeholder='upload your file' />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col className="col-md-7">
                    <div className='textbx'>
                      <h3 className="mb-4">Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quisque dictum.</h3>

                      <FormGroup className="d-flex align-items-center">
                        <Label>Library</Label>
                        <p>Shutterstock</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Assets ID</Label>
                        <p><a href="https://www.shutterstock.com/" target="_blank">1156565656</a></p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Title</Label>
                        <p>Lemon</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Type</Label>
                        <p>Photo</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Expiry</Label>
                        <p>50 days</p>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Known Restrictions</Label>
                        <div className='allowassets noclick d-flex flex-wrap'>
                          <div className='checkbox'><Input type="checkbox" id="checksm1" disabled></Input><label htmlFor="checksm1">Web</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm2" disabled></Input><label htmlFor="checksm2">Print</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm3" disabled></Input><label htmlFor="checksm3">Outdoor</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm4" disabled></Input><label htmlFor="checksm4">Others</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm5" disabled></Input><label htmlFor="checksm5">Print</label></div>
                          <div className='checkbox'><Input type="checkbox" id="checksm6" disabled></Input><label htmlFor="checksm6">Outdoor</label></div>
                        </div>
                      </FormGroup>
                      <FormGroup className="d-flex align-items-center">
                        <Label>Agency</Label>
                        <p>Lemon LLC</p>
                      </FormGroup>
                    </div>
                  </Col>
                </Row>


                <div className="extrainfosec mt-2">
                  <Nav tabs>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '1' })}
                        onClick={() => { this.toggleTabs('1'); }}
                      >
                        Description
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: this.state.activeTab === '2' })}
                        onClick={() => { this.toggleTabs('2'); }}
                      >
                        Extra Info
                      </NavLink>
                    </NavItem>
                  </Nav>
                  <TabContent activeTab={this.state.activeTab}>
                    <TabPane tabId="1">
                      <div className="tabcontent p-3">
                        <Row>
                          <Col sm="12">
                            <ScrollArea smoothScrolling={0.8} style={{ maxHeight: '195px' }}>
                              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam imperdiet est magna, hendrerit aliquam nibh placerat cursus. Phasellus sed nisl dolor. In malesuada finibus nulla, sit amet pretium dui porttitor at. Fusce pellentesque velit at lacinia sodales. Fusce fringilla leo a nisl posuere sodales. Sed at tellus in leo hendrerit iaculis. Suspendisse vel fermentum sem. Vestibulum ante ipsum primis in faucibus orci luctus et ultrices posuere cubilia curae.</p>
                            </ScrollArea>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    <TabPane tabId="2">
                      <div className="tabcontent p-3">
                        <Row className="justify-content-between">
                          <Col className="col-md-6">
                            <FormGroup className="d-flex align-items-center">
                              <Label>Purchased on</Label>
                              <p>2022-08-05</p>
                            </FormGroup>
                            <FormGroup className="d-flex align-items-center">
                              <Label>Contributer</Label>
                              <p>Agency XYZ</p>
                            </FormGroup>
                          </Col>
                          <Col className="col-md-6">
                            <FormGroup className="d-flex align-items-center">
                              <Label>Cost (USD)</Label>
                              <p>500 USD</p>
                            </FormGroup>
                            <FormGroup className="d-flex align-items-center">
                              <Label>Account Name</Label>
                              <p>ALJ Corp</p>
                            </FormGroup>
                          </Col>
                        </Row>

                        <div className="extrainfobx">
                          <FormGroup className="d-flex align-items-center mb-0">
                            <Label>ALJ brand purchased for</Label>
                            <div className='allowassets d-flex flex-wrap'>
                              <div className='checkbox'><Input type="checkbox" id="checkextra1" disabled></Input><label htmlFor="checkextra1">FRV</label></div>
                              <div className='checkbox'><Input type="checkbox" id="checkextra2" disabled></Input><label htmlFor="checkextra2">Jimco</label></div>
                              <div className='checkbox'><Input type="checkbox" id="checkextra3" disabled></Input><label htmlFor="checkextra3">ALJ Health</label></div>
                              <div className='checkbox'><Input type="checkbox" id="checkextra4" disabled></Input><label htmlFor="checkextra4">Jenoa</label></div>
                            </div>
                          </FormGroup>
                        </div>

                      </div>
                    </TabPane>
                  </TabContent>
                </div>

              </div>
            </ModalBody>
          </div> */}
        </Slider>

        <div id="detelepopup">
          <div className="modal-header">
            <h5 className="modal-title">Adobe Stock</h5>
            <Row className="mt-3 justify-content-between">
              <Col className="d-flex">
                <Input type="text" name="deletefield" id='deletefield' placeholder="Type 'delete' to confirm" />
                <Button className="btn btn-danger ml-3" onClick={this.deleteCurrentRecord}><i className="fas fa-trash-alt"></i> Delete</Button>
                <Button className="btn btn-default ml-3" onClick={closeDeleteRecord}><i className="fas fa-times"></i> Cancel</Button>
              </Col>
            </Row>
          </div>
        </div>

      </Modal>
    )
  }
}

export default Viewassets;
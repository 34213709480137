//main live db
export const PARSE_APP_ID ='VwJs7ULc0QAYtgQY6oHSiui1R7hmK5BYtw9ypURA'
export const PARSE_API_URL ='https://parseapi.back4app.com/'
export const PARSE_JAVASCRIPT_KEY ='YBWca435cOqtTWI5TNjwYKpIRUTl6wWtBDj0rHZm'

//clone of live db
// export const PARSE_APP_ID ='tQMlIqyImrOQHkyzJvLUGmmlrCicTGLoTllBxH1W'
// export const PARSE_API_URL ='https://aljipappclonev2.back4app.io/'
// export const PARSE_JAVASCRIPT_KEY ='qA5v8EUSqVLsUpV0YWUrI4lgqVQsXDLbobifoJjA'

// export const PARSE_APP_ID ='hZR63SzuJSTcgoUV0XBI7zRXUIK0olowFbsGInYQ'
// export const PARSE_API_URL ='https://parseapi.back4app.com/'
// export const PARSE_JAVASCRIPT_KEY ='Fm9XlJbiXZvZTqm3olRz669WJrWFBOqF4YPUxA4K'


import jQuery from 'jquery';
var Parse = require('parse');

export const getMediaLicenseLibrary = async () => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.equalTo("recordstatus", "active");
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getLicenseType = async () => {
    var LicenseType = Parse.Object.extend("LicenseType");
    var query = new Parse.Query(LicenseType);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getAssetType = async () => {
    var AssetType = Parse.Object.extend("AssetType");
    var query = new Parse.Query(AssetType);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getGeoRestriction = async () => {
    var GeoRestriction = Parse.Object.extend("GeoRestriction");
    var query = new Parse.Query(GeoRestriction);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getAgency = async () => {
    var Agency = Parse.Object.extend("Agency");
    var query = new Parse.Query(Agency);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getBrand = async () => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.equalTo("status", true);
    query.ascending("name");
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}




export const addRecord = async (fieldvalues) => {

    var obj = new Parse.Object("MediaLicenseAssetAgency");
 
    obj.set("recordstatus", fieldvalues.recordstatus);
    obj.set("assetimage", fieldvalues.assetimage);


    if (fieldvalues.allowedlicense) {
        var extentObject = Parse.Object.extend("LicenseType");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.allowedlicense);
        obj.set("licensetype", parsepoint);
        obj.set("licensetypename", parsepoint.get('slug'));
    }


    if (fieldvalues.libraryid) {
        var extentObject = Parse.Object.extend("MediaLicenseLibrary");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.libraryid);
        obj.set("medialicenselibraryid", parsepoint);
    }

    obj.set("asseturl", fieldvalues.asseturl);
    obj.set("assetid", fieldvalues.assetid);
    obj.set("title", fieldvalues.title);


    if (fieldvalues.typeid) {
        var extentObject = Parse.Object.extend("AssetType");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.typeid);
        obj.set("assettypeid", parsepoint);
        obj.set("assettypetext", parsepoint.get('name'));
    }


    obj.set("prepetuity", fieldvalues.prepetuity);
    obj.set("expiry", fieldvalues.expiry);





    var restrearray = [];
    for (var i = 0; i < fieldvalues.knownrestrictions.length; ++i) {
        var extentObject = Parse.Object.extend("GeoRestriction");
        var licQuery = new Parse.Query(extentObject);
        let validjuris = await licQuery.get(fieldvalues.knownrestrictions[i]);
        restrearray.push(validjuris);
    }
    // obj.set("allowedlicense", restrearray);
    obj.set("georestriction", restrearray);



    if (fieldvalues.aganecyid) {
        var extentObject = Parse.Object.extend("Agency");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.aganecyid);
        obj.set("agencyid", parsepoint);
    }


    obj.set("description", fieldvalues.description);
    obj.set("purchasedon", fieldvalues.purchasedon);
    obj.set("contributer", fieldvalues.contributer);
    if (fieldvalues.cost) {
        obj.set("cost", parseFloat(fieldvalues.cost));
    }
    obj.set("accountname", fieldvalues.accountname);


    var brandarray = [];
    for (var i = 0; i < fieldvalues.brandpurchased.length; ++i) {
        var extentObject = Parse.Object.extend("Brand");
        var licQuery = new Parse.Query(extentObject);
        let validjuris = await licQuery.get(fieldvalues.brandpurchased[i]);
        brandarray.push(validjuris);
    }
    obj.set("aljbrand", brandarray);



    return obj.save().then((obj) => {
        return obj;
    }, (error) => {
        console.log(error)
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const checkFieldValid = async (tabnumber, statevalues) => {

    var errstat = true;

    if (tabnumber === 2) {
        if (statevalues.title === "") {
            jQuery('#title').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('#title').removeClass("is-invalid");
        }
    }

    if (tabnumber === 3) {
        if (statevalues.libraryid === "") {
            jQuery('#libraryid').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('#libraryid').removeClass("is-invalid");
        }

        if (statevalues.typeid === "") {
            jQuery('#assettype').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('#assettype').removeClass("is-invalid");
        }


        if (statevalues.mainImagefile.length === 0) {
            jQuery('.libraryimgwrap').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('.libraryimgwrap').removeClass("is-invalid");
        }
    }

    if (tabnumber === 4) {
        if (statevalues.allowedlicense === "") {
            jQuery('.licenseitems').addClass("is-invalid");
            errstat = false;
        } else {
            jQuery('.licenseitems').removeClass("is-invalid");
        }

        if(statevalues.prepetuity===false && statevalues.expiry===null){
            jQuery('#expiry_date').addClass("is-invalid");
            errstat = false;
        }else{
            jQuery('#expiry_date').removeClass("is-invalid");
        }
    }


 


    if (errstat === false) {
        return false;
    } else {
        return true;
    }

}


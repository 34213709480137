import jQuery from 'jquery';
var Parse = require('parse');



export const getAgencybyName = async (name) => {
    var Agency = Parse.Object.extend("Agency");
    var query = new Parse.Query(Agency);
    query.ascending("name");
    query.equalTo("name", name);
    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getBrandbyName = async (namearray) => {
    var Brand = Parse.Object.extend("Brand");
    var query = new Parse.Query(Brand);
    query.ascending("name");

    const myArray = namearray.split(",");
    query.containedIn('name', myArray);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getAssetTypebyName = async (name) => {
    var AssetType = Parse.Object.extend("AssetType");
    var query = new Parse.Query(AssetType);
    query.ascending("name");
    query.equalTo('name', name);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getGeoRestrictionbyName = async (namearray) => {
    var GeoRestriction = Parse.Object.extend("GeoRestriction");
    var query = new Parse.Query(GeoRestriction);
    query.ascending("name");

    const myArray = namearray.split(",");
    query.containedIn('name', myArray);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getLicenseTypebyName = async (name) => {
    var LicenseType = Parse.Object.extend("LicenseType");
    var query = new Parse.Query(LicenseType);
    query.ascending("name");
    query.equalTo('name', name);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}

export const getMediaLicenseLibrarybyName = async (name) => {
    var MediaLicenseLibrary = Parse.Object.extend("MediaLicenseLibrary");
    var query = new Parse.Query(MediaLicenseLibrary);
    query.ascending("name");
    query.equalTo('name', name);

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}



export const addRecord = async (fieldvalues) => {

    var obj = new Parse.Object("MediaLicenseAssetImport");
 
    obj.set("recordstatus", fieldvalues.recordstatus);
    obj.set("assetimage", fieldvalues.assetimage);


    if (fieldvalues.allowedlicense) {
        var extentObject = Parse.Object.extend("LicenseType");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.allowedlicense);
        obj.set("licensetype", parsepoint);
        obj.set("licensetypename", parsepoint.get('slug'));
    }


    if (fieldvalues.libraryid) {
        var extentObject = Parse.Object.extend("MediaLicenseLibrary");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.libraryid);
        obj.set("medialicenselibraryid", parsepoint);
    }

    obj.set("asseturl", fieldvalues.asseturl);
    obj.set("assetid", fieldvalues.assetid);
    obj.set("title", fieldvalues.title);


    if (fieldvalues.typeid) {
        var extentObject = Parse.Object.extend("AssetType");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.typeid);
        obj.set("assettypeid", parsepoint);
        obj.set("assettypetext", parsepoint.get('name'));
    }


    obj.set("prepetuity", fieldvalues.prepetuity);
    obj.set("expiry", fieldvalues.expiry);





    var restrearray = [];
    for (var i = 0; i < fieldvalues.knownrestrictions.length; ++i) {
        var extentObject = Parse.Object.extend("GeoRestriction");
        var licQuery = new Parse.Query(extentObject);
        let validjuris = await licQuery.get(fieldvalues.knownrestrictions[i]);
        restrearray.push(validjuris);
    }
    // obj.set("allowedlicense", restrearray);
    obj.set("georestriction", restrearray);



    if (fieldvalues.aganecyid) {
        var extentObject = Parse.Object.extend("Agency");
        var parseqry = new Parse.Query(extentObject);
        let parsepoint = await parseqry.get(fieldvalues.aganecyid);
        obj.set("agencyid", parsepoint);
    }


    obj.set("description", fieldvalues.description);
    obj.set("purchasedon", fieldvalues.purchasedon);
    obj.set("contributer", fieldvalues.contributer);
    if (fieldvalues.cost) {
        obj.set("cost", parseFloat(fieldvalues.cost));
    }
    obj.set("accountname", fieldvalues.accountname);


    var brandarray = [];
    for (var i = 0; i < fieldvalues.brandpurchased.length; ++i) {
        var extentObject = Parse.Object.extend("Brand");
        var licQuery = new Parse.Query(extentObject);
        let validjuris = await licQuery.get(fieldvalues.brandpurchased[i]);
        brandarray.push(validjuris);
    }
    obj.set("aljbrand", brandarray);



    return obj.save().then((obj) => {
        return obj;
    }, (error) => {
        console.log(error)
        return false;
    }).catch(function (error) {
        return false;
    });
}


export const getAllMediaLicenseAssetImport = async (name) => {
    var MediaLicenseAssetImport = Parse.Object.extend("MediaLicenseAssetImport");
    var query = new Parse.Query(MediaLicenseAssetImport);
    query.include("licensetype");
    query.include("georestriction");
    query.include("medialicenselibraryid");
    query.include("agencyid");
    query.include("aljbrand");

    return query.find().then((records) => {
        return records;
    }, (error) => {
    });
}


// export const deleteAllRecords = async (objectid) => {
//     var obj = new Parse.Object("MediaLicenseAssetImport");
//     obj.id = objectid;
//     const response = await obj.destroy({});
 
//     console.log(response)

//     if (response) {
//         return true;
//     }
// }

export const deleteRecords = async (objectid) => {
    var obj = new Parse.Object("MediaLicenseAssetImport");
    obj.id = objectid;
    const response = await obj.destroy({});
 
    console.log(response)

    if (response) {
        return true;
    }
}

import React from 'react';
import {
	InputGroup,
	InputGroupAddon,
	InputGroupText,
	Input,
	CustomInput,
	Form,
	Row,
	Col,
	Label,
	Button,
	Alert
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { auth } from '../../firebase';
import validators from './validators';

import loginBg from '../../assets/images/loginBg.jpg';
import imgloginheader from '../../assets/images/login-header.jpg';
import imglogo from '../../assets/images/logo.png';
import imgview from 'assets/images/icons/view.png';
import imgnoview from 'assets/images/icons/no-view.png';
import iconPassword from '../../assets/images/icons/password.png';
import iconEmail from '../../assets/images/icons/email.png';
import backgroundVideo from '../../assets/videos/alj-our-locations-in-saudi-arabia.mp4';
import { getCurrentUser, authenticateLoggedIn, login, isSuperAdmin, authenticateLoginpage } from '../../services/parse';


const sidebarBackground = {
	backgroundImage: "url(" + loginBg + ")",
	backgroundRepeat: "no-repeat",
	backgroundPosition: "center center",
	backgroundSize: "cover"
};

class Getpwd extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			username: '',
			password: '',
			userError: '',
			passError: '',
			invalidError: 'd-none',
		};

	}

	async componentDidMount(evt) {
		//auth start
		//authenticateLoggedIn();

		authenticateLoginpage();

		// isSuperAdmin().then((value) => {
		//     if (value === true) {
		//         this.setState({isSuper: 'yes'});

		//         var currentuserdet = currentUserDetails(); 
		//         this.setState({currentuserdetails: currentuserdet.attributes.username});

		//         if(currentuserdet.attributes.username=='superadmin'){
		//             menuActivation("#/employee/employeelist");
		//         }

		//     } else {
		//         this.setState({isSuper: 'no'});
		//     }
		// });
		//auth end


	}





	handleUsernameChange = (evt) => {
		this.setState({ username: evt.target.value });
	}
	handlePassChange = (evt) => {
		this.setState({ password: evt.target.value });
	}

	handleSubmit = () => {
		const { username, password } = this.state;
		let status = false;

		if (username === '') {
			this.setState({ userError: 'is-invalid' });
			this.setState({ passError: '' });
			return
		} else if (password === '') {
			this.setState({ userError: '' });
			this.setState({ passError: 'is-invalid' });
		} else {
			login({ username: username, password: password }).then((value) => {
				if (value === true) {
					window.location.assign('#/home');
				} else {
					this.setState({ invalidError: 'd-block' });
				}
			});
		}
	}



	handleClick() {
		var elem = document.getElementById('loginform');
		elem.style.transition = "all 2s ease-in-out";
		elem.style.display = "none";
		document.getElementById('recoverform').style.display = "block";
	}
	render() {
		return <div className="auth-wrapper align-items-center d-flex">
			{/*--------------------------------------------------------------------------------*/}
			{/*Login2 Cards*/}
			{/*--------------------------------------------------------------------------------*/}

			<div className="videobg">
			<video autoPlay loop muted id="myVideo">
			<source src={backgroundVideo} type="video/mp4"/>
			</video>
			</div>

			<div className="container-fluid  p-0">

<div className="loginheader">
	<a href="https://www.alj.com/" className="logo" target="_blank"><img src={imglogo} alt={"imglogo"} /></a>
</div>

					<Row className="no-gutters justify-content-center">
						<div className="loginbx">
							<div className="p-5">
							<h1 className="title mt-2 mb-5">Intellectual Property Management</h1>

								<Alert color="danger" className={this.state.invalidError}>
									Invalid Credentials
                       </Alert>
								<Form className="mt-4" id="loginform" action="/dashbaord">
									{/* <Label for="email" className="font-medium">Email</Label> */}
									<InputGroup className="mb-3" size="lg">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<img src={iconPassword} alt="iconPassword" width="30" />
										</InputGroupText>
									</InputGroupAddon>
									<Input type="password" className={this.state.passError} id="password" placeholder="New Password" autoComplete="current-password" required onChange={this.handlePassChange} />
									<InputGroupAddon addonType="append">
										<InputGroupText>
											<a href="javascript:"><img src={imgnoview} alt="view" width="25" /></a>
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
								<InputGroup className="mb-3" size="lg">
									<InputGroupAddon addonType="prepend">
										<InputGroupText>
											<img src={iconPassword} alt="iconPassword" width="30" />
										</InputGroupText>
									</InputGroupAddon>
									<Input type="password" className={this.state.passError} id="password2" placeholder="Confirm Password" autoComplete="current-password" required onChange={this.handlePassChange} />
									<InputGroupAddon addonType="append">
										<InputGroupText>
											<a href="javascript:"><img src={imgnoview} alt="view" width="25" /></a>
										</InputGroupText>
									</InputGroupAddon>
								</InputGroup>
									<Row className="mb-3 mt-3">
										<Col xs="12">
											<Button size="lg" className="btn-grey" onClick={this.handleSubmit} block>Get the password</Button>
										</Col>
									</Row>
								<div className="forgotpwd">
									<Link to="/authentication/login">
										Back to Login
          							</Link>
								</div>
								</Form>
							</div>

						</div>
					</Row>
					<footer className="footer">
      <Row className="justify-content-between align-items-center">
        <Col>
        <ul>
          <li><a href="http://brand.alj.com/" target="_blank">brand.alj.com</a></li>
        </ul>
        </Col>
        <Col md="auto">
        <ul>
          <li><a href="https://www.alj.com/" target="_blank">alj.com</a></li>
        </ul>
        </Col>
        </Row>
      </footer>
				</div>
		</div>;
	}
}

export default Getpwd;

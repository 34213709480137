import AuthRoutes from './authroutes.jsx';

import agencySubmission from '../views/agencysubmission/agencySubmission.jsx';
import home from '../views/home/home.jsx';
import bulkpurchase from '../views/bulkpurchase/bulkpurchase.jsx';
import bulkImport from '../views/bulkpurchase/bulkimport/bulkImport.jsx';

import iconChatKey from '../assets/images/icons/icon-chat-key.png';
import iconMedia from '../assets/images/icons/icon-media.png';


var auths = [].concat(AuthRoutes);

var ThemeRoutes = [
  {
    path: '/home',
    name: 'Home',
    icon: 'mdi mdi-account-circle',
    iconimg: iconChatKey,
    component: home
  },
  {
    path: '/agencysubmission',
    name: 'Agency Submission',
    icon: 'mdi mdi-account-circle',
    iconimg: iconChatKey,
    component: agencySubmission
  },
  // {
  //   path: '/bulkpurchase',
  //   name: 'Bulk Purchase',
  //   icon: 'mdi mdi-account-circle',
  //   iconimg: iconChatKey,
  //   component: bulkpurchase
  // },
  // {
  //   path: '/bulkimport',
  //   name: 'Bulk Import',
  //   icon: 'mdi mdi-account-circle',
  //   iconimg: iconChatKey,
  //   component: bulkImport
  // },

];
export default ThemeRoutes;

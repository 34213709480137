import React from 'react';
import {
  Card, CardHeader, CardBody, Alert, Table, Row, Col, Input, Form, FormGroup, CardTitle, InputGroup, InputGroupAddon, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import { addBreadcrumb, addHeaderTitle } from '../../services/parse';
import noImage from '../../assets/images/dummy2.jpg';


class home extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textClass: '',
    };

  }

  async componentDidMount(evt) {
    addHeaderTitle('Welcome');
    // addBreadcrumb('Home / Welcome');
  }


  render() {
    return (
      <div className="animated fadeIn">

        <div className="welcomepage">
          <Row>

            <Col md="6">
              <Card>
                <CardBody className="p-5">
                  <h3 className="mb-4">Welcome to Abdul Latif Jameel brand compliance portal</h3>
                  <p className="fs18">A strong Brand has many elements, but one of the most important is a global consistency in the way the organization, and more importantly the valuable associates who make up that company present themselves. Part of this is how procure licensed assets from external content producers and platforms.</p>
                  <p className="fs18">To help us share pride in our work and consistency in our Team, this asset compliance portal has been created to ensure the licensed the use of stock content across our portfolio of brands easier and also to ensure timely renewal of licenses in order to fulfill our obligations as a responsible corporate entity.</p>
                  <p className="fs18">Please use the options below to register your recent asset purchases</p>
                  <Link to="/agencysubmission" className="btn btn-default mt-3">Register a single purchase</Link>
                  {/* <Link to="/bulkpurchase">Register bulk purchases</Link> */}
                </CardBody>
              </Card>
            </Col>

            <Col md="6">
              <Card>
                <CardBody>
                  <div className="assetscard">
                    <div className="imgbx">
                      <img src={noImage} alt="img" />
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>

      </div>
    )
  }
}

export default home;

import React from 'react';
import {
  Nav,
  NavItem,
  NavLink,
  Button,
  Navbar,
  NavbarBrand,
  Collapse,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCarousel,
  Progress,
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Form,
  FormGroup,
  Input
} from 'reactstrap';
import * as data from './data.jsx';
import jQuery from 'jquery';
import { Link } from 'react-router-dom';

/*--------------------------------------------------------------------------------*/
/* Import images which are need for the HEADER                                    */
/*--------------------------------------------------------------------------------*/
import logodarkicon from '../../assets/images/logo-dark.png';
import defaultuser from '../../assets/images/icons/default-user.png';

import { authenticateLoggedIn, getCurrentUser, logout } from '../../services/parse';

// var idleTime = 0;
// jQuery(document).ready(function () {
//   var idleInterval = setInterval(timerIncrement, 60000); // 1 minute

//   jQuery(this).mousemove(function (e) {
//     idleTime = 0;
//   });
//   jQuery(this).keypress(function (e) {
//     idleTime = 0;
//   });
// });

// function timerIncrement() {
//   idleTime = idleTime + 1;
//   if (idleTime > 29) { // 30 minutes
//     logout('yes');
//   } else {
//     var expiry = new Date();
//     expiry.setTime(expiry.getTime() + (2 * 60 * 1000)); // 2 mnt
//     document.cookie = "cookieLogin=yes; expires=" + expiry.toGMTString();
//   }
// }


class Header extends React.Component {

  constructor(props) {
    super(props);
    this.showMobilemenu = this.showMobilemenu.bind(this);
    this.state = {
      loginusername: '',
    };
  }


  showMobilemenu() {
    document.getElementById('sidebarbg').classList.toggle('show-sidebar');
  }


  componentWillMount = async () => {

    // var cookiename = 'cookieLogin';
    // var value = "; " + document.cookie;
    // var parts = value.split("; " + cookiename + "=");
    // if (parts.length == 2) {
    //   var part2 = parts.pop().split(";").shift();
    // } else {
    //   var part2 = "";
    // }
    // if (part2 !== 'yes') {
    //   logout('yes');
    // }

  }
  async componentDidMount(evt) {

    // authenticateLoggedIn();

    // var curruser = getCurrentUser();
    // if (curruser) {
    //   this.setState({ loginusername: curruser.get('firstName') + ' ' + curruser.get('lastName') });
    // }

  }

  render() {

    // var cookiename = 'cookieLogin';
    // var value = "; " + document.cookie;
    // var parts = value.split("; " + cookiename + "=");
    // if (parts.length == 2) {
    //   var part2 = parts.pop().split(";").shift();
    // } else {
    //   var part2 = "";
    // } 
    // if(part2 !== 'yes'){ 
    //   logout('yes');
    // }


    return (
      <header className="header">
        <Row className="justify-content-center align-items-center">
          {/* <Col md="auto" className="w275">
            <div className="d-flex align-items-center userbx">
              <div className="imgbx">
                <img src={defaultuser} alt="user" className="rounded-circle" width="50" />
              </div>
              <div className="ml-3 lineheight-1">
                <p className="mb-0">Welcome,</p>
                <h4 className=" mb-0">{this.state.loginusername}</h4>
              </div>
            </div>
          </Col> */}
          <Col>
            <div className="iptext">
              <h2 className="mb-0">Intellectual Property Management</h2>
              <a className="nav-toggler d-block d-md-none" onClick={this.showMobilemenu}><i className="ti-menu ti-close"></i></a>
            </div>
          </Col>
          <Col md="auto">
            <div className="headerlogo">
              <Link to="/home">
                <img src={logodarkicon} alt="homepage" className="light-logo" />
              </Link>
            </div>
          </Col>
        </Row>
      </header>
    );
  }
}
export default Header;

import React from 'react';
import {
  Nav,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  Row,
  Col,
} from 'reactstrap';
import * as data from './data.jsx';
import jQuery from 'jquery';
import { isAdmin } from '../../services/user';
import { logout } from '../../services/parse';

import iconLougout from '../../assets/images/icons/icon-logout.png';
import iconAlert from '../../assets/images/icons/icon-alert.png';
import iconSetting from '../../assets/images/icons/icon-setting.png';
import defaultuser from '../../assets/images/icons/default-user.png';
import { authenticateLoggedIn} from '../../services/parse';


class Toppanel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      currentrole: '',
    };
  }


  async componentWillMount(evt) {

    // authenticateLoggedIn();

    // var fullurl = window.location.href;
    // var origin = window.location.origin.toString();
    // var res = fullurl.split(origin);

    window.location.assign('#/home');

    // var isadminval = await isAdmin();
    // if (isadminval) {
    //   if (isadminval[0].get('role')) {
    //     var currentrole = isadminval[0].get('role').get('name');
    //     this.setState({ currentrole: currentrole });
    //   } else {
    //     var currentrole = "";
    //   }
    // } else {
    //   var currentrole = "";
    // }

    

 
  }




  logout = () => {
    logout('yes');
  }


  render() {
 
    return (
      <div className="toppanel">

        {/* <div className="loader"></div> */}

        <Row className="justify-content-center align-items-center">
          <Col>
            <div className="maintitle">
              <span className="breadcrumbs" id="topbreadcrumb">Home</span>
              <h1 className="mb-0 lineheight-1" id="headertitle">Dashboard</h1>
            </div>
          </Col>
          <Col md="auto">
            <nav className="navbar navbar-expand-md">
              <Nav className="ml-auto float-right align-items-center" navbar>
                {/*--------------------------------------------------------------------------------*/}
                {/* Start Notifications Dropdown                                                   */}
                {/*--------------------------------------------------------------------------------*/}
                {/* <li className="nav-item">
                  <a href="javascript:" className="nav-link" onClick={this.logout}>
                    <i className="icon-login mr-1 ml-1"></i> 
                    Logout</a>
                </li> */}
                {/* <UncontrolledDropdown nav inNavbar>
                  <DropdownToggle nav>
                    <i className="icon-bell" />
                    
                    Alert
                </DropdownToggle>
                  <DropdownMenu right className="mailbox">
                    <span className="with-arrow">
                      <span className="bg-primary" />
                    </span>
                    <div className="titlebx d-flex no-block align-items-center p-3 text-white mb-2">
                      <div className="">
                        <h4 className="mb-0">4 New</h4>
                        <p className="mb-0">Notifications</p>
                      </div>
                    </div>
                    <div className="message-center notifications">
                      {data.notifications.map((notification, index) => {
                        return (
                          <a href="" className="message-item" key={index}>
                            <span className={"btn btn-circle btn-" + notification.iconbg}>
                              <i className={notification.iconclass} />
                            </span>
                            <div className="mail-contnet">
                              <h5 className="message-title">{notification.title}</h5>
                              <span className="mail-desc">
                                {notification.desc}
                              </span>
                              <span className="time">{notification.time}</span>
                            </div>
                          </a>
                        );
                      })}
                    </div>
                    <a className="nav-link text-center pt-1 mb-1 text-dark" href=";">
                      <strong>Check all notifications</strong>{' '}
                      <i className="fa fa-angle-right d-inline-block" />
                    </a>
                  </DropdownMenu>
                </UncontrolledDropdown>
                <li className="nav-item"><a href="/pages/login" className="nav-link">
                  <i className="icon-settings mr-1 ml-1"></i>
                  Settings</a>
                </li> */}
                {/*--------------------------------------------------------------------------------*/}
                {/* End Notifications Dropdown                                                     */}
                {/*--------------------------------------------------------------------------------*/}
              </Nav>
            </nav>
          </Col>
        </Row>
      </div>
    );
  }
}
export default Toppanel;

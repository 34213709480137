import React from 'react';
import {
  Card, CardHeader, CardBody, Alert, Table, Row, Col, Input, Form, FormGroup, CardTitle, InputGroup, InputGroupAddon, Button,
  Modal, ModalHeader, ModalBody, ModalFooter, Pagination, PaginationItem, PaginationLink,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import Select, { components } from "react-select";
import jQuery from 'jquery';
import { addBreadcrumb, addHeaderTitle } from '../../services/parse';
import SampleAsset from './bulkimport/SampleAsset.csv';

class bulkpurchase extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      textClass: '',
    };

  }

  async componentDidMount(evt) {
    addHeaderTitle('Welcome');
    addBreadcrumb('Home / Welcome');
  }


  render() {
    return (
      <div className="animated fadeIn">
        Bulk Purchase Welcome

        <Col className="col-auto">
          <a href={SampleAsset} className="btn btn-primary" download>Download sample CSV</a>
        </Col>

        <Link to="/bulkimport">
          Submit bulk purchases
        </Link>

      </div>
    )
  }
}

export default bulkpurchase;
